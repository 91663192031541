import ContactBar from "../components/ContactBar";
import ImageGallery from "../components/ImageGallery";

function GalleryPage() {
  return (
    <div>
      <ImageGallery />
      <ContactBar />
    </div>
  );
}
export default GalleryPage;
