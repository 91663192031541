import OrderMenu from "../components/OrderMenu";
import ContactBar from "../components/ContactBar";

function OrderPage() {
  return (
      <div>
          <OrderMenu />
          <ContactBar />
      </div>
  )

}
export default OrderPage;
