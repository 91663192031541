const ORDER_DATA = [
  {
    id: "1",
    title: "Beignets",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 13,
    image:
      "https://www.louisianacookin.com/wp-content/uploads/2020/10/lc_image003_RGB_1080x1080-1.jpg",
  },
  {
    id: "2",
    title: "Crab Omlete",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 12,
    image:
      "https://img.delicious.com.au/qh3ckgQJ/del/2017/10/crab-omelette-53318-2.jpg",
  },
  {
    id: "3",
    title: "Brunch Plate",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 13,
    image:
      "https://cdn.vox-cdn.com/thumbor/Ctm5V4OWKzzJccTVRRk-GlpbuBo=/0x0:1100x733/1200x900/filters:focal(436x111:612x287):no_upscale()/cdn.vox-cdn.com/uploads/chorus_image/image/62567991/Faustina_s_Chicken_and_Waffles.0.0.jpg",
  },
  {
    id: "4",
    title: "Steak and Eggs",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 23,
    image:
      "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/20191219-seo-steak-eggs-delish-ehg-8987-jpg-1579900349.jpg",
  },
  {
    id: "5",
    title: "Shrimp and Grits",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 11,
    image: "https://www.rouses.com/wp-content/uploads/2017/09/Shrimp-Grits.jpg",
  },
  {
    id: "6",
    title: "Crawfish Ettoufee",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 33,
    image:
      "https://acadianatable.com/wp-content/uploads/2013/03/CrawfishEtouffeePlate.jpg",
  },
  {
    id: "7",
    title: "Crab Cakes",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 28,
    image:
      "https://food.fnr.sndimg.com/content/dam/images/food/fullset/2007/8/2/0/TU0303_Crab_Cake.jpg.rend.hgtvcom.616.462.suffix/1393125096227.jpeg",
  },
  {
    id: "8",
    title: "Seared Jumbo Scallops",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 35,
    image:
      "https://img.grouponcdn.com/deal/pRPzhXA5Yx4QgXLTU3MC/ze-2048x1242/v1/c870x524.jpg",
  },
  {
    id: "9",
    title: "Lobster Tail",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 49,
    image:
      "https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/grilled-lobster-tail-004-1553897798.jpg",
  },
  {
    id: "10",
    title: "Grilled Shrimp",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 30,
    image:
      "https://whatgreatgrandmaate.com/wp-content/uploads/2020/05/cajun-shrimp-and-sausage-4-683x1024.jpg",
  },
  {
    id: "11",
    title: "Oxtail Platter",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 34,
    image:
      "https://assets.bonappetit.com/photos/5eab53c6eee08767a7c6ab62/4:3/w_1572,h_1179,c_limit/Kwame-Braised-Oxtails.jpg",
  },
  {
    id: "12",
    title: "Blackened Fish Tacos",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 22,
    image:
      "https://preventionrd.com/wp-content/uploads/2016/10/Blackened-Fish-Tacos-with-Cilantro-Slaw-and-Sriracha-Mayo-3.jpg",
  },
  {
    id: "13",
    title: "Shrimp Fettuccine",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 25,
    image:
      "https://thecozyapron.com/wp-content/uploads/2018/07/cajun-shrimp-pasta_thecozyapron_1.jpg",
  },
  {
    id: "14",
    title: "Creole Pasta",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 29,
    image:
      "https://www.bunsinmyoven.com/wp-content/uploads/2021/03/shrimp-and-sausage-pasta-625x938.jpg",
  },
  {
    id: "15",
    title: "Key Lime Pie",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 11,
    image:
      "https://easyhealthyrecipes.com/wp-content/uploads/2021/03/Vegan-Key-Lime-Pie-3.jpg",
  },
  {
    id: "16",
    title: "New York Style Cheesecake",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 13,
    image:
      "https://www.seriouseats.com/thmb/UGwN-duUT6336M5RFL8scx4MHpE=/1500x1125/filters:fill(auto,1)/__opt__aboutcom__coeus__resources__content_migration__serious_eats__seriouseats.com__2019__05__20190520-cheesecake-vicky-wasik-34-16488b3671ae47b5b29eb7124dbaf938.jpg",
  },
  {
    id: "17",
    title: "Bananas Foster",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 9,
    image: "https://www.mastercook.com/app/Image/20625712/6677400.jpg",
  },
  {
    id: "18",
    title: "Tiramisu",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 10,
    image:
      "https://tastesbetterfromscratch.com/wp-content/uploads/2017/04/Tiramisu-15.jpg",
  },
  {
    id: "19",
    title: "Triple Chocolate Mousse Cake",
    description:
      "Vestibulum eu lobortis turpis. Quisque et nisi quis libero scelerisque tempor. Sed neque sapien, placerat a facilisis nec, gravida sit amet augue. Cras nec egestas. ",
    price: 35,
    image:
      "https://petitworldcitizen.files.wordpress.com/2013/12/mousse-cake-2.jpg",
  },
];

export default ORDER_DATA;
